export const useMenuHQAction = () => {
  const menu = [
    {
      title: 'trader',
      key: 'trader',
      menu: [
        {
          title: 'Operation',
          to: '/hq/trader/',
          submenu: [
            { title: 'Approval', to: '/hq/trader/contract/approval', indent: 0 },
            { title: 'Account', to: '', indent: 0 },
            { title: 'Contract', to: '/hq/trader/contract/sell', indent: 0 },
            { title: 'List', to: '/hq/trader/contract/list', indent: 0 }
          ]
        },
        {
          title: 'Setup',
          to: '/hq/marketing/',
          submenu: [
            { title: 'Buyer', to: '', indent: 0 },
            { title: 'Basis', to: '', indent: 0 },
            { title: 'Notification', to: '', indent: 0 },
            { title: 'Price', to: '', indent: 0 }
          ]
        }

      ]
    },
    {
      title: 'marketing',
      key: 'marketing',
      menu: [
        {
          title: 'Operation',
          to: '/hq/marketing/',
          submenu: [
            { title: 'Approval', to: '/hq/marketing/contract/approval', indent: 0 },
            { title: 'Account', to: '', indent: 0 },
            { title: 'Contract', to: '/hq/marketing/contract/buy', indent: 0 },
            { title: 'List', to: '/hq/marketing/contract/list', indent: 0 }
          ]
        },
        {
          title: 'Setup',
          to: '/hq/marketing/',
          submenu: [
            { title: 'Destination', to: '', indent: 0 },
            { title: 'Supplier', to: '/hq/marketing/supplier/listsupplier', indent: 0 },
            { title: 'Mill', to: '/hq/marketing/supplier/listmill', indent: 24 },
            { title: 'Contact Person', to: '/hq/marketing/supplier/listcontactperson', indent: 24 }

          ]
        }

      ]
    },
    {
      key: 'purchaser',
      menu: [

        {
          title: 'List',
          to: '/HQ/purchaser/list',
          submenu: []
        }
      ]
    },
    {
      key: 'management',
      menu: [

        {
          title: 'Approval',
          to: '/HQ/management/list',
          submenu: []
        }

      ]
    }

  ]
  return menu
}
